var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c("goals-table", {
        attrs: {
          tableName: _vm.goalTables.MANAGER_PENDING_REQUESTS,
          goals: _vm.goals,
          loading: _vm.loading
        },
        on: {
          updateOptions: _vm.updateOptions,
          manageGoalRequest: _vm.manageGoalRequest
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }